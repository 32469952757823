@use '@angular/material' as mat;

/*** ngx loading bar ***/
.ngx-spinner {
  color: $primary !important;
}

.ngx-bar {
  background-color: $primary !important;
}

/* Form required */
.required:after {
  content: " *";
  color: red;
}

/* Custom Style */
.disabled-link {
  pointer-events: none;
}

/* Rating stars */
.stars {
  --percent: calc(var(--rating) / 5 * 100%);
  display: inline-block;
  font-size: 15px;
  font-family: sans-serif;
  line-height: 1;
}

.stars::before {
  content: "★★★★★";
  letter-spacing: 0px;
  background: linear-gradient(90deg,
      #fc0 var(--percent),
      #eaeaea var(--percent));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.wide-panel {
  width: 55% !important;
}

/************************
Angular Material 
************************/
:root {
  // Paginator
  @include mat.paginator-overrides((
    container-text-color: inherit,
    container-text-size: 11px,
    container-background-color: inherit,
  ));
  // Radio
  @include mat.radio-overrides((
    label-text-size: 12px
  ));
  // Button
  @include mat.button-overrides((
    filled-container-height: 35px,
    outlined-container-height: 35px,
    text-container-height: 35px
  ));
  // Table
  @include mat.table-overrides((
    background-color: inherit,
    row-item-label-text-font: inherit,
    header-headline-font: inherit,
    footer-supporting-text-font: inherit
  ));
  // Dialog
  @include mat.dialog-overrides((
    container-max-width: 800px
  ));
  // Expansion
  @include mat.expansion-overrides((
    container-background-color: inherit,
    container-shape: 3px,
    actions-divider-color: #ddd
  ));
  // Tabs
  @include mat.tabs-overrides((
    label-text-size: 0.9em
  ));
}
.mat-form-field-fluid {
  display: block !important;
}

.mat-icon-sm {
  height: 17px !important;
  width: 17px !important;
  font-size: 17px !important;
}

.mat-mdc-menu-item {
  display: flex !important;
  align-items: center;
}

.mat-icon {
  display: flex !important;
  align-items: center; /* Ensures icon is vertically centered */
}
.mat-mdc-form-field-icon-suffix{
  padding: 0 5px 0 5px !important;
}
.mat-mdc-menu-item-text {
  display: flex !important;
  align-items: center; /* Ensures text is vertically centered */
}
.mat-mdc-tooltip-surface{
  min-height: inherit !important;
}
.bottom-sheet-class .mat-bottom-sheet-container.cdk-dialog-container.mat-bottom-sheet-container-large {
  padding: 0px !important
}

.cdk-overlay-container,
.cdk-overlay-pane {
  z-index: 9999 !important;
}

html[data-bs-theme="light"] {

  .success-dialog {
    --mdc-text-button-label-text-color: #FFF !important;
    --mat-snack-bar-button-color: #FFF !important;
    --mdc-snackbar-container-color: var(--bs-primary) !important;
    --mat-snack-bar-button-color: var(--bs-white) !important;
  }

  .danger-dialog {
    --mat-snack-bar-button-color: #FFF !important;
    --mdc-snackbar-container-color: var(--bs-danger) !important;
    --mat-snack-bar-button-color: var(--bs-white) !important;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__trailing {
    border-color: var(--bs-gray-400);
  }

}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 0.5em !important;
}

.mat-mdc-menu-content .mat-mdc-menu-item,
.mat-mdc-select-panel .mat-mdc-option.mdc-list-item,
.mat-mdc-option {
  min-height: 40px; 
  .mdc-list-item__primary-text{
    width: 100%;
  }
}

.mat-mdc-optgroup-label .mdc-list-item__primary-text {
  font-weight: 500;
}

html[data-bs-theme="dark"] {

  .mat-mdc-table,
  .mdc-data-table__header-cell,
  .mat-mdc-paginator {
    background: var(--bs-body-bg)
  }
}

/**********************************
Angular Material Overide Variables
**********************************/
html {
  --mat-table-row-item-label-text-size: 12px;
}

/**********************************
Media Queries
**********************************/
@media (max-width: 768px) {
  .wide-panel {
    width: 100% !important;
  }
}