//
// DualListBox
//

// Base
.dual-listbox {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;

  // Search input
  .dual-listbox__search {
    width: 300px;
    margin-bottom: 1%;
    padding: 10px;
    border: 1px solid $gray-200;
    color: $input-plaintext-color;
    outline: none !important;
    @include border-radius($border-radius);

    &:active,
    &:focus {
      border-color: $input-focus-border-color !important;
    }

    &.dual-listbox__search--hidden {
      display: none;
    }
  }

  // Container
  .dual-listbox__container {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    .dual-listbox__title {
      font-weight: 500;
      font-size: 1.1rem;
      padding: 0.75rem 0.75rem;
      border: 1px solid $gray-200;
      border-bottom: 0;
      @include border-top-start-radius($border-radius);
      @include border-top-end-radius($border-radius);
    }

    .dual-listbox__buttons {
      margin: 0 1.5rem;
      display: -ms-flexbox;
      display: -webkit-box;
      display: flex;
      -ms-flex-direction: column;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;

      .dual-listbox__button {
        @include button-reset();
        @include border-radius($border-radius);
        margin-bottom: 0.5rem !important;
        padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
        color: $dark;
        font-weight: 500;
        font-size: 0.9rem;
        background-color: $gray-100;
        transition: all 0.3s ease;

        i {
          transition: $transition-base;
          font-size: 0.6rem;
          color: $text-muted;
        }

        &:hover {
          transition: $transition-base;
          color: $primary;

          i {
            transition: $transition-base;
            color: $primary;
          }
        }
      }
    }

    .dual-listbox__selected,
    .dual-listbox__available {
      height: 300px;
      overflow-y: auto;
      padding: 0;
      width: 300px;
      margin-top: 0;
      -webkit-margin-before: 0;
      color: $input-plaintext-color;
      border: 1px solid $gray-200;
      @include border-bottom-start-radius($border-radius);
      @include border-bottom-end-radius($border-radius);

      .dual-listbox__item {
        padding: 0.75rem 0.75rem;
        border-bottom: 1px solid $gray-200;

        &:last-child {
          border-bottom: 0;
        }

        &.dual-listbox__item--selected {
          background-color: $gray-100;
        }
      }
    }
  }
}
