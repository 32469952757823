@use "@angular/material" as mat;
@use "./theme-colors" as theme;

@include mat.elevation-classes();
@include mat.app-background();

/***** Light theme *****/
$prime-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: theme.$primary-palette
  ),
  typography: (
    brand-family: $font-family-sans-serif,
    plain-family: $font-family-sans-serif
  ),
  density: (
    scale: -4
  )
));

/***** Dark theme *****/
$prime-theme-dark: mat.define-theme((
  color: (
    theme-type: dark,
    primary: theme.$tertiary-palette
  ),
  typography: (
    brand-family: $font-family-sans-serif,
    plain-family: $font-family-sans-serif,
  ),
  density: (
    scale: -4
  )
));


// Include all component themes based on the theme variable
html {
  @include mat.elevation-classes();
  @include mat.app-background();
  @include mat.all-component-themes($prime-theme);
}

html[data-bs-theme="dark"] {
  @include mat.all-component-colors($prime-theme-dark);
}