/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style.scss";
@import "./assets/sass/style.angular.scss";

// Keenicons - High quality and pixel perfect font icons available in 3 styles, duotone, outline and solid for prime elements
@import "./assets/plugins/keenicons/duotone/style.css";
@import "./assets/plugins/keenicons/outline/style.css";
@import "./assets/plugins/keenicons/solid/style.css";
@import "./assets/plugins/flaticon/flaticon.css";
@import "./assets/plugins/flaticon2/flaticon.css";


/* Balham Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-balham.css';

/* KEEP THIS AT LAST SO WE OVER_RIDE CLASSES */
@import "./assets/sass/custom.style.scss";