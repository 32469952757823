//
// SVG Icon
//

@mixin svg-icon-color($color, $important: false) {
    svg {
        g {
            [fill] {
                transition: fill 0.3s ease;
                fill: $color valueif($important, !important, null);
            }
        }

        &:hover {
            g {
                [fill] {
                    transition: fill 0.3s ease;
                }
            }
        }
    }
}

@mixin svg-icon-transition() {
    svg {
        g {
            [fill] {
                transition: fill 0.3s ease;
            }
        }
    }
}


@mixin svg-icon-size($size, $important: false) {
    svg {
        height: $size valueif($important, !important, null);
        width: $size valueif($important, !important, null);
    }
}