body {
  margin: 0;
  padding: 0;
}

.splash-screen {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Helvetica, "sans-serif";
  background-color: #f2f3f8;
  color: #5e6278;
  line-height: 1;
  font-size: 14px;
  font-weight: 400;
}

.splash-screen span {
  color: #5e6278;
  transition: none !important;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

.splash-screen img {
  margin-left: calc(100vw - 100%);
  margin-bottom: 30px;
  height: 30px !important;
}

[data-bs-theme="dark"] .splash-screen {
  background-color: #151521;
  color: #92929f;
}

[data-bs-theme="dark"] .splash-screen span {
  color: #92929f;
}

#root {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}
